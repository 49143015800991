import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PropTypes from 'prop-types';
import Article from '../components/article';

const TagIndex = ({ pageContext, data, location }: { pageContext: any; data: any; location: any }) => {
  const { tag: tag } = pageContext;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <SEO title={`All posts on ${tag}`} />
      <Article>
        <h2>All posts about '{tag}'</h2>
        <ul>
          {posts.map((post: any) => {
            return (
              <li key={post.fields.slug}>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>

                <small> — {post.frontmatter.date}</small>
              </li>
            );
          })}
        </ul>
      </Article>
    </Layout>
  );
};
TagIndex.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }),
};

export default TagIndex;

export const pageQuery = graphql`
  query ($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM D, YYYY")
          title
        }
      }
    }
  }
`;
