import React from 'react';

const Article = ({ children }: { children: any }) => {
  return (
    <article itemScope itemType="http://schema.org/Article">
      <div className="article-container mx-auto">
        {children}
      </div>
    </article>
  );
};

export default Article;
